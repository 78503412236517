import React from 'react';
import { GatsbySeo as SEO } from 'gatsby-plugin-next-seo';

import styled, { ThemeProvider } from 'styled-components';
import Layout from '@components/Layout/Layout';
import theme from '@theme/index';
import ScoopsBackground from '@components/Layout/Backgrounds/ScoopsBackground';
import { ContentType } from '@components/Sections/interfaces';
import Section from '@components/Sections/Section';
import { HeaderContextProvider } from '@components/Layout/Header/headerContext';

const notFoundContent: ContentType = {
  __typename: 'SanityFormattedText',
  text: {
    __typename: 'SanityLocalePortableText',
    localized: `[{"_key":"0996da7ad055","_type":"block","children":[{"_key":"2132580f5c210","_type":"span","marks":[],"text":"Page not found."}],"markDefs":[],"style":"normal"}]`,
  },
  textColor: {
    __typename: 'SanityColor',
    hex: '#000000',
  },
  backgroundColor: {
    __typename: 'SanityColor',
    hex: '#FFFFFF',
  },
  jumpLinkMenu: undefined,
  shareArticleButtons: undefined,
  _key: undefined,
};

const Spacer = styled.div`
  margin-bottom: 500px;
`;

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <ScoopsBackground>
      <HeaderContextProvider announcementId="">
        <Layout>
          <SEO noindex={true} />
          <Section content={notFoundContent} />
          <Spacer />
        </Layout>
      </HeaderContextProvider>
    </ScoopsBackground>
  </ThemeProvider>
);

export default NotFoundPage;
