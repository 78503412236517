import React, { ReactNode, FC } from 'react';

import TanScoop from '@assets/backgrounds/tan.svg';
import {
  ScoopsWrapper,
  ScoopBottom,
  ContentWrapper,
  HeaderPlaceholder,
} from './ScoopsBackgroundStyled';

interface IScoopsBackgroundProps {
  children: ReactNode;
}

const ScoopsBackground: FC<IScoopsBackgroundProps> = ({ children }) => (
  <ScoopsWrapper>
    <HeaderPlaceholder />
    <ScoopBottom>
      <TanScoop />
    </ScoopBottom>
    <ContentWrapper>{children}</ContentWrapper>
  </ScoopsWrapper>
);

// ts-prune-ignore-next
export default ScoopsBackground;
