import styled from 'styled-components';
import { HEADER_SIZE } from '../Header/constants';

export const BACKGROUND_STACKING_INDEX = -1000;

export const ScoopsWrapper = styled.div`
  position: absolute;
  z-index: ${BACKGROUND_STACKING_INDEX};
  left: 0;
  right: 0;
  overflow: hidden;
`;

export const ScoopBottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: ${BACKGROUND_STACKING_INDEX};

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 0;
`;

export const HeaderPlaceholder = styled.div`
  height: ${HEADER_SIZE}px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: ${BACKGROUND_STACKING_INDEX + 1};
`;
